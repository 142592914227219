
import { defineComponent, inject } from 'vue';
import Hero from '../components/Hero.vue';
import Stepslider from '../components/Stepslider.vue';
import OverviewItem from '../components/OverviewItem.vue';
import Heading from '../components/Heading.vue';
import Clients from '../components/Clients.vue';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: 'Home',
    components: {
        Hero,
        Stepslider,
        OverviewItem,
        Heading,
        Clients,
    },
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);

        return {
            data,
        };
    },
});
