<template>
    <div class="stepslider flex flex-wrap mb-xxl pb-xxl" ref="el">
        <div class="stepslider__image w-12 l:w-8 xl:w-9 m-down:mb-r l-up:pr-m">
            <div class="ratio-16by9">
                <img
                    v-if="current"
                    :srcset="imageSizes(cdnUrl(current.image), [400, 800, 1200, 1500])"
                    :alt="current.name"
                    class="object-cover ratio-stretch rounded"
                />
            </div>
        </div>
        <ul class="stepslider__steps w-12 l:w-4 xl:w-3 m:flex m:flex-wrap m:columns">
            <li
                v-for="(step, index) in steps"
                :key="step.id"
                class="m:w-4"
                @click="setCurrentStep(index, 'manual')"
            >
                <div
                    class="stepslider__step relative mb-r rounded transition px-l py-m"
                    :class="{
                        'animate-timer': current && current.id === step.id && !manual,
                        'is-active bg-accent-3': current && current.id === step.id,
                        'cursor-pointer bg-hover hover:bg-accent-3': !current || current.id !== step.id,
                    }"
                >
                    <div class="color-accent weight-m">{{ step.name }}</div>
                    <div class="stepslider__steptext">
                        {{ step.text }}
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import {
    defineComponent, ref, computed, inject,
} from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS } from '../types';

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    return vertInView;
}

export default defineComponent({
    name: 'Stepslider',
    setup() {
        const data = inject(REMOTE_TEXTS);

        const steps = computed(() => {
            if (data.value?.stepslidersteps[0]?.items) {
                return data.value.stepslidersteps[0].items;
            }
            return [];
        });

        let stepslidertimeout;

        const manual = ref(false);
        const el = ref(null);
        const delay = 8000;
        const currentStep = ref(undefined);
        function setCurrentStep(index: number, trigger: 'manual'|'timer') {
            if (trigger === 'manual') {
                manual.value = true;
                clearTimeout(stepslidertimeout);
            }
            if (index > -1 && index <= steps.value.length) {
                currentStep.value = index;
            }
        }

        setTimeout(() => {
            currentStep.value = 0;
        }, 10);
        const current = computed(() => {
            if (steps.value && steps.value.length) {
                return steps.value[currentStep.value];
            }
            return undefined;
        });

        function setNextStep() {
            if (!manual.value) {
                if (isInViewport(el.value)) {
                    const currentStepIndex = currentStep.value;
                    const nrOfSteps = steps.value.length;
                    const nextStepIndex = (currentStepIndex + 1) % nrOfSteps;

                    setCurrentStep(nextStepIndex, 'timer');
                }
                stepslidertimeout = setTimeout(setNextStep, delay);
            }
        }
        stepslidertimeout = setTimeout(setNextStep, delay);

        return {
            steps,
            current,
            manual,
            setCurrentStep,

            imageSizes,
            cdnUrl,
            el,
        };
    },
});
</script>

<style>
    .stepslider__step .stepslider__steptext {
        overflow: hidden;
        max-height: 30rem;
        transition: opacity .4s ease .4s, max-height .8s ease-out;
    }
    .stepslider__step:not(.is-active) .stepslider__steptext {
        max-height: 0;
        opacity: 0;
        transition: opacity .4s ease, max-height .8s ease-out;
    }

    .stepslider__step::after {
        content: '';
        bottom: 0;
        left: 0;
        height: 0.4rem;
        background: var(--color-accent);
        display: block;
        position: absolute;
        border-radius: 0 0 0.4rem 0.4rem;
        width: 0;
        transition: none;
    }
        .stepslider__step.animate-timer::after {
            width: 100%;
            transition: width 8s linear;
        }
</style>
