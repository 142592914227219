<template>
    <div>
        <Hero />
        <section id="moreinfo" class="c w-xxl animate-in">
            <Heading
                :title="data.stepslider_title"
                :text="data.stepslider_text"
                position="left"
            />
            <Stepslider />
        </section>

        <section id="overview" class="c w-xxl animate-in">
            <Heading
                :title="data.overview_title"
                :text="data.overview_text"
                position="center"
            />
            <div class="overview flex flex-wrap columns pt-xl">
                <OverviewItem
                    v-for="item in data.overview"
                    :key="'overview' + item.id"
                    :data="item"
                    class="w-12 m-up:w-6 xl:w-4 mb-l"
                />
            </div>
        </section>

        <Clients class="animate-in" />
    </div>
</template>

<script lang="ts">
import { defineComponent, inject } from 'vue';
import Hero from '../components/Hero.vue';
import Stepslider from '../components/Stepslider.vue';
import OverviewItem from '../components/OverviewItem.vue';
import Heading from '../components/Heading.vue';
import Clients from '../components/Clients.vue';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: 'Home',
    components: {
        Hero,
        Stepslider,
        OverviewItem,
        Heading,
        Clients,
    },
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);

        return {
            data,
        };
    },
});
</script>
