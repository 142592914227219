
import { defineComponent, inject, computed } from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: 'Hero',
    setup() {
        const data = inject(REMOTE_TEXTS);

        const branches = computed(() => {
            if (data.value?.branches[0]?.items) {
                return data.value.branches[0].items;
            }
            return [];
        });
        const amountOfBranches = computed(() => ({
            '--amountOfBranches': branches.value.length,
        }));

        const highlights = computed(() => {
            if (data.value?.highlights[0]?.items) {
                return data.value.highlights[0].items;
            }
            return [];
        });

        return {
            data,
            branches,
            amountOfBranches,
            highlights,
            imageSizes,
            cdnUrl,
        };
    },
});
