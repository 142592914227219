<template>
    <div class="heading w-12 s:w-10 m:w-8 l-up:w-6 xl:w-5 mb-l" :class="classes">
        <h2>{{ title }}</h2>
        <div class="color-secondary w-12 m-up:w-10" :class="classes">
            {{ text }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue';

export default defineComponent({
    name: 'Heading',
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        position: {
            type: String as PropType<'left'|'center'>,
            required: true,
            default: 'left',
        },
    },
    setup(props) {
        const classes = computed(() => {
            if (props.position === 'center') {
                return 'text-center mx-auto';
            }
            // else: left
            return 'text-left';
        });

        return {
            classes,
        };
    },
});
</script>
