
import { defineComponent, PropType, computed } from 'vue';

export default defineComponent({
    name: 'Heading',
    props: {
        title: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        position: {
            type: String as PropType<'left'|'center'>,
            required: true,
            default: 'left',
        },
    },
    setup(props) {
        const classes = computed(() => {
            if (props.position === 'center') {
                return 'text-center mx-auto';
            }
            // else: left
            return 'text-left';
        });

        return {
            classes,
        };
    },
});
