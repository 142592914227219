<template>
    <section id="clients" class="relative">
        <!-- top bar -->
        <div class="c w-xxl flex flex-wrap align-end justify-between z-5">
            <Heading
                :title="data.clients_title"
                :text="data.clients_text"
                position="left"
            />
        </div>

        <!-- logos -->
        <ul class="clients__logos flex columns py-xxl justify-center">
            <li
                v-for="client in clientsList"
                :key="client.id"
                class="mr-r bg-hover rounded client__logo px-l py-r cursor-pointer hover:bg-accent-3 transition"
                @click="selectClient(client.id)"
            >
                <img
                    v-if="client.logo"
                    :srcset="imageSizes(cdnUrl(client.logo), [250, 500])"
                    :title="client.name"
                    :alt="client.name"
                    loading="lazy"
                    class="ratio-stretch object-contain"
                />
            </li>
        </ul>

        <!-- reference -->
        <Reference
            v-if="selectedClient"
            :data="selectedClient"
        />
    </section>
</template>

<script lang="ts">
import {
    defineComponent, inject, computed, ref, watch,
} from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import Heading from './Heading.vue';
import Reference from './Reference.vue';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: '',
    components: {
        Heading,
        Reference,
    },
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);

        function shuffle(array) {
            array.sort(() => 0.5 - Math.random());
            return array;
        }

        const clientsList = computed(() => shuffle([...data.value.clients]));

        const visibleReferenceID = ref(undefined);
        function setVisibleReference(val) {
            if (val) {
                visibleReferenceID.value = val[0].id;
            }
        }
        function selectClient(id) {
            visibleReferenceID.value = id;
        }
        watch(clientsList, setVisibleReference, { immediate: true });

        const selectedClient = computed(() => {
            if (visibleReferenceID.value) {
                return clientsList.value.find((it) => it.id === visibleReferenceID.value);
            }
            return false;
        });

        return {
            data,

            clientsList,
            selectedClient,
            selectClient,

            imageSizes,
            cdnUrl,
        };
    },
});
</script>

<style>
    #clients {
        --blob-min: 2vw;
        --blob-max: 6rem;
    }
    #clients::after, #clients::before {
        content: '';
        width: max(var(--blob-min), var(--blob-max));
        height: max(var(--blob-min), var(--blob-max));
        background: var(--color-accent-3);
        position: absolute;
        z-index: 1;
        pointer-events: none;
        right: max(var(--width-xxl-sidepadding), 2rem);
        top: calc(max(var(--blob-min), var(--blob-max)) * -0.7);
        display: block;
        border-radius: 50%;
    }
    #clients::after {
        --blob-min: 4vw;
        --blob-max: 15rem;
        top: 0;
        right: calc(max(var(--blob-min), var(--blob-max)) * 0.45);
    }
</style>
