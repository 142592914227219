<template>
    <section id="hero" class="relative">
        <div class="c w-xxl">
            <div class="hero__content relative z-5 w-12 s:w-10 m:w-8 l-up:w-6 xl:w-6 flex flex-col l-up:justify-around m-down:mt-xxl">
                <div>
                    <h2>
                        {{ data.home_title }}
                        <div
                            class="brancheslider rounded-s bg-accent color-contrast"
                            :style="amountOfBranches"
                        >
                            <ul>
                                <li
                                    v-for="branche in branches"
                                    :key="branche.id"
                                    class="color-primary px-r py-r"
                                >
                                    {{ branche.name }}
                                </li>
                            </ul>
                        </div>
                    </h2>

                    <div class="tags flex flex-wrap align-center my-xl">
                        <span
                            v-for="highlight in highlights"
                            :key="highlight.id"
                            class="flex align-center bg-accent-3 rounded-s mr-s mb-s pr-m py-s"
                            :class="{ 'pl-m': !highlight.icon, 'pl-s': highlight.icon }"
                        >
                            <icon
                                v-if="highlight.icon"
                                v-html="$getIcon(highlight.icon)"
                                class="mr-s color-accent w-24 h-24"
                            />
                            {{ highlight.name }}
                        </span>
                    </div>
                </div>

                <div class="buttons m-down:mt-xxl">
                    <a href="#contact" class="button bg-accent -l mr-s">
                        {{ data.home_button_title_1 }}
                    </a>
                    <a href="#moreinfo" class="button color-accent hover:color-primary -ghost -l">
                        {{ data.home_button_title_2 }} <icon class="w-24 h-24" v-html="$getIcon('arrow-right-circle')" />
                    </a>
                </div>
            </div>

        </div>
        <template
                v-for="n in [1,2,3]"
                :key="'image' + n"
            >
            <img
                v-if="data['home_photo_' + n]"
                :srcset="imageSizes(cdnUrl(data['home_photo_' + n]), [150, 300, 600, 800])"
                class="hero__image shadow-l z-1 m-down:hide"
                :class="'hero__image--' + n"
            />
        </template>
    </section>
</template>

<script lang="ts">
import { defineComponent, inject, computed } from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: 'Hero',
    setup() {
        const data = inject(REMOTE_TEXTS);

        const branches = computed(() => {
            if (data.value?.branches[0]?.items) {
                return data.value.branches[0].items;
            }
            return [];
        });
        const amountOfBranches = computed(() => ({
            '--amountOfBranches': branches.value.length,
        }));

        const highlights = computed(() => {
            if (data.value?.highlights[0]?.items) {
                return data.value.highlights[0].items;
            }
            return [];
        });

        return {
            data,
            branches,
            amountOfBranches,
            highlights,
            imageSizes,
            cdnUrl,
        };
    },
});
</script>

<style>
    #hero:after {
        content: '';
        pointer-events: none;
        position: absolute;
        width: 60vw;
        height: 60vw;
        border-radius: 50%;
        background: var(--color-accent-3);
        top: -25vw;
        right: -25vw;
    }
    .hero__content {
        margin-bottom: 10vh;
    }
    @media (min-width: 62em) {
        .hero__content {
            min-height: 65vh;
        }
    }
    .hero__content h2 {
        --fontsize: calc(var(--size-2) * 1.2);
        font-size: var(--fontsize);
    }
        .brancheslider {
            display: inline-block;
            vertical-align: sub;
            overflow: hidden;
            height: calc(var(--fontsize) + calc(var(--size-r) * 2));
            line-height: 1;
            position: relative;
            --animation-duration: 5s;
        }
        .brancheslider ul {
            animation: brancheslider var(--animation-duration) infinite;
            animation-timing-function: steps(var(--amountOfBranches), end);
        }

            @keyframes brancheslider {
                0% {
                    transform: translateY(0%);
                }
                100% {
                    transform: translateY(-100%);
                }
            }
</style>
