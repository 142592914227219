
import {
    defineComponent, inject, computed, ref, watch,
} from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import Heading from './Heading.vue';
import Reference from './Reference.vue';
import { REMOTE_TEXTS } from '../types';

export default defineComponent({
    name: '',
    components: {
        Heading,
        Reference,
    },
    props: {},
    setup() {
        const data = inject(REMOTE_TEXTS);

        function shuffle(array) {
            array.sort(() => 0.5 - Math.random());
            return array;
        }

        const clientsList = computed(() => shuffle([...data.value.clients]));

        const visibleReferenceID = ref(undefined);
        function setVisibleReference(val) {
            if (val) {
                visibleReferenceID.value = val[0].id;
            }
        }
        function selectClient(id) {
            visibleReferenceID.value = id;
        }
        watch(clientsList, setVisibleReference, { immediate: true });

        const selectedClient = computed(() => {
            if (visibleReferenceID.value) {
                return clientsList.value.find((it) => it.id === visibleReferenceID.value);
            }
            return false;
        });

        return {
            data,

            clientsList,
            selectedClient,
            selectClient,

            imageSizes,
            cdnUrl,
        };
    },
});
