
import {
    defineComponent, PropType,
} from 'vue';
import { nl2br, imageSizes, cdnUrl } from '@projectfive/services';
import { Client } from '../types';

export default defineComponent({
    name: 'Reference',
    props: {
        data: {
            type: Object as PropType<Client>,
            required: true,
        },
    },
    setup() {
        return {
            nl2br,
            imageSizes,
            cdnUrl,
        };
    },
});
