<template>
    <div>
        <div class="overview__image mb-r ratio-16by9">
            <img
                :srcset="imageSizes(cdnUrl(data.photo), [250, 500, 750])"
                :alt="data.name"
                loading="lazy"
                class="ratio-stretch object-cover rounded-s"
            />
        </div>
        <div class="w-11">
            <h4 class="color-accent mb-s">{{ data.name }}</h4>
            <div class="color-secondary" v-html="nl2br(data.text)" />
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { nl2br, imageSizes, cdnUrl } from '@projectfive/services';

export default defineComponent({
    name: 'OverviewItem',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {
            nl2br,
            imageSizes,
            cdnUrl,
        };
    },
});
</script>
