
import {
    defineComponent, ref, computed, inject,
} from 'vue';
import { imageSizes, cdnUrl } from '@projectfive/services';
import { REMOTE_TEXTS } from '../types';

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    const windowHeight = (window.innerHeight || document.documentElement.clientHeight);

    const vertInView = (rect.top <= windowHeight) && ((rect.top + rect.height) >= 0);
    return vertInView;
}

export default defineComponent({
    name: 'Stepslider',
    setup() {
        const data = inject(REMOTE_TEXTS);

        const steps = computed(() => {
            if (data.value?.stepslidersteps[0]?.items) {
                return data.value.stepslidersteps[0].items;
            }
            return [];
        });

        let stepslidertimeout;

        const manual = ref(false);
        const el = ref(null);
        const delay = 8000;
        const currentStep = ref(undefined);
        function setCurrentStep(index: number, trigger: 'manual'|'timer') {
            if (trigger === 'manual') {
                manual.value = true;
                clearTimeout(stepslidertimeout);
            }
            if (index > -1 && index <= steps.value.length) {
                currentStep.value = index;
            }
        }

        setTimeout(() => {
            currentStep.value = 0;
        }, 10);
        const current = computed(() => {
            if (steps.value && steps.value.length) {
                return steps.value[currentStep.value];
            }
            return undefined;
        });

        function setNextStep() {
            if (!manual.value) {
                if (isInViewport(el.value)) {
                    const currentStepIndex = currentStep.value;
                    const nrOfSteps = steps.value.length;
                    const nextStepIndex = (currentStepIndex + 1) % nrOfSteps;

                    setCurrentStep(nextStepIndex, 'timer');
                }
                stepslidertimeout = setTimeout(setNextStep, delay);
            }
        }
        stepslidertimeout = setTimeout(setNextStep, delay);

        return {
            steps,
            current,
            manual,
            setCurrentStep,

            imageSizes,
            cdnUrl,
            el,
        };
    },
});
