<template>
    <section id="reference" class="c w-xxl flex flex-wrap align-stretch columns mt-l mb-xxl">
        <div class="w-12 l:w-6 xl:w-4">
            <div class="reference__card reference__text flex flex-col justify-center shadow-xl">
                <div>
                    <div class="color-accent weight-m mb-s">
                        {{ data.name }}<br />
                        {{ data.company_name }}
                    </div>
                    <div
                        class="color-secondary"
                        v-html="nl2br(data.text)"
                    />
                </div>
            </div>
        </div>
        <div v-if="data.photo_1" class="w-6 l:w-3 xl:w-4">
            <div class="reference__card ratio-1by1">
                <img
                    :srcset="imageSizes(cdnUrl(data.photo_1), [250, 400, 600, 800])"
                    class="ratio-stretch object-cover"
                    loading="lazy"
                />
            </div>
        </div>
        <div v-if="data.photo_2" class="w-6 l:w-3 xl:w-4">
            <div class="reference__card ratio-1by1">
                <img
                    :srcset="imageSizes(cdnUrl(data.photo_2), [250, 400, 600, 800])"
                    class="ratio-stretch object-cover"
                    loading="lazy"
                />
            </div>
        </div>
    </section>
</template>

<script lang="ts">
import {
    defineComponent, PropType,
} from 'vue';
import { nl2br, imageSizes, cdnUrl } from '@projectfive/services';
import { Client } from '../types';

export default defineComponent({
    name: 'Reference',
    props: {
        data: {
            type: Object as PropType<Client>,
            required: true,
        },
    },
    setup() {
        return {
            nl2br,
            imageSizes,
            cdnUrl,
        };
    },
});
</script>

<style>

</style>
