
import { defineComponent } from 'vue';
import { nl2br, imageSizes, cdnUrl } from '@projectfive/services';

export default defineComponent({
    name: 'OverviewItem',
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {
            nl2br,
            imageSizes,
            cdnUrl,
        };
    },
});
